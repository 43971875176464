.alert {
    z-index: 99;
    top: -0.9rem;
    border-radius: 0;
}

.alert-primary {
    color: $black;
    background-color: $light-green;
    border-color: $light-green;
}