.Header {
  &-container {
    @extend .row, .col-xs-12, .between-xs, .middle-xs;
    margin-top: 1.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 0;
  }
  &-logo {
    width: 38px;
    height: 38px;
    img {
      width: 100%;
      height: auto;
    }
    &--desktop {
      display: none;
    }
    &--mobile {
      display: flex;
    }
  }
  &-button {
    font-size: 0.75rem;
    color: $color-primary-button !important;
    text-decoration: underline !important;
    text-decoration-line: underline;
    text-decoration-style: solid;
    font-weight: 800;
  }
}

@media only screen and (min-width: 48em) {
  .Header {
    &-container {
      margin-top: 38px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &-logo {
      width: auto;
      height: 34px;
      img {
        width: auto;
        height: 100%;
      }
      &--desktop {
        display: flex;
      }
      &--mobile {
        display: none;
      }
    }
  }
}
