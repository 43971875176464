// Primary Font: San Francisco
@font-face {
  font-family: "Gotham Medium";
  src: url("../../assets/fonts/Gotham-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Book";
  src: url("../../assets/fonts/Gotham-Book.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Medium";
  src: url("../../assets/fonts/Gotham-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Light";
  src: url("../../assets/fonts/Gotham-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url("../../assets/fonts/Ubuntu-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu Medium';
  src: url("../../assets/fonts/Ubuntu-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

.light {
  font-family: "Gotham Light";
}

.medium {
  font-family: "Gotham Medium";
}

.book {
  font-family: "Gotham Book";
}
