.btn-outline-pink {
    background-color: $color-primary;
    color: $white;
    font-size: $font-regular;
    border-radius: 14px;
    padding: 12px 55px;
    &:hover {
        background-color: transparent;
        border: 1px solid $color-primary;
        color: $color-primary;
    }
}

.btn-outline-white {
    color: $color-primary;
    font-size: $font-regular;
    border-radius: 14px;
    padding: 12px 55px;
    &:hover {
        color: $grey;
    }
}

.btn-outline-black {
    background-color: transparent;
    color: $black;
    border: 1px solid $black;
    font-size: $font-regular;
    border-radius: 14px;
    padding: 0.5rem 3rem;
    &:hover {
        color: $white;
        background-color: $dark-grey;
        border-color: $dark-grey;
    }
}
