.Social-Grid {
  &-container {
    margin-top: 26px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    grid-gap: 10px;
    grid-row-gap: 21px;
    justify-content: center;
    align-content: center;
  }
  &-item {
    display: grid;
    align-items: center;
    justify-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}
