.modal-dialog {
    margin: 0 auto;
    display:table;
    position: absolute;
    left: 0;
    right:0;
    top: 50%; 
    -webkit-transform:translateY(-50%) !important;
    -moz-transform:translateY(-50%) !important;
    -ms-transform:translateY(-50%) !important;
    -o-transform:translateY(-50%) !important;
    transform:translateY(-50%) !important;
    .modal-content {
        border-radius: 10px;
        border: none;
        .modal-header {
            border-bottom: none;
        }
        .modal-body {
            padding: 1rem 3rem;
            .elo-subtitle {
                font-family: $sf-regular;
            }
        }
        .modal-footer {
            border-top: none;
            padding: .75rem 3rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .modal-dialog {
        width: 95%;
        left: 2.5%;
    }
}