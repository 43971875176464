.Footer {
  &-container {
    @extend .row, .center-xs, .middle-xs, .col-xs-12;
    min-height: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 100%;
    height: 2rem;
  }
  &-copy {
    font-family: "Ubuntu Medium", sans-serif;
    color: #838383;
    font-size: 0.9rem;
    margin: 0;
    a {
      color: #838383;
    }
  }
  &-company {
    color: #000000 !important;
    font-weight: 900;
  }
  &-logo {
    width: 1.4rem;
    margin-bottom: 0.5rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
