// Colors
$color-primary: #00538b;
$color-secondary: #5c0f8b;
$color-primary-button: #00538b;
$color-inverse-button: #e81f76;
// Base colors
$white: #ffffff;
$black: #232121;
$dark-grey: #909093;
$light-grey: #838383;
$black-ios: #1C1C1E;
$grey: #636366;
$light-green: #aaf1e0;
$blue: #00538b;
// Fonts Weights
$sf-regular: 'Gotham Medium', sans-serif;
$sf-book: 'Gotham Book', sans-serif;
$sf-light: 'Gotham Light', sans-serif;
$sf-medium: 'Gotham Medium', sans-serif;
$sf-bold: 'Ubuntu', sans-serif;
$sf-regular-ubuntu: 'Ubuntu Medium', sans-serif;

// Font Sizes
$font-large: 26px;
$font-largeTwo: 22px;
$font-medium: 20px;
$font-regular: 16px;
$font-regularTwo:17px;
$font-small: 14px;
$font-smallTwo: 12px;
$font-verysmall: 10px;

$font-title: 28px;
$font-subtitle: 24px;
