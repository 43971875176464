
/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}


.text-light {
  font-family: $sf-regular;
  color: $color-primary;
  font-size: $font-regular;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.img-oval {
  background: linear-gradient(to right, #ee307c -1%, #f05529 -1%, #e2307d 16%, #2c3491 63%, #20507e 87%);
  width: fit-content;
  height: fit-content;
  border-radius: 10rem;
  border-color: transparent;
  border-style: solid;
  border-width: 0.18rem;
}

.social-title {
  color: $color-primary;
  font-family: $sf-medium;
  font-size: $font-title;
}
.social-subtitle {
  color: $black;
  margin-bottom: 5px;
}
.social-address {
  color: $dark-grey;
  font-family: $sf-book;
}
.social-subtitle, .social-address {
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.bc-primary-button {
  background-color: $color-primary-button
}
.social-inverse-button {
  width: 411px;
  height: 44px;
  border-radius: 30px;
  border-width: 1px;
  color: $white;
  background-color: $color-primary-button
}
.bc-secondary-subtitle {
  color: $color-primary-button
}
.bioText{
  width: 412px;
  line-height: 23px;
  font-size: $font-regularTwo;
  color:$grey;
  font-family: $sf-book;
}
.networkIcon{
  margin-left: 17px;
  margin-top: 17px;
  width: 102px;
  height: 102px;
}
@media screen and (max-width: 1024px) {
  .networkIcon{
    margin-left: 14px;
    margin-top: 14px;
    width: 82px;
    height: 82px;
  }
  .social-inverse-button {
    width: 331px;
  }
  .social-title {
    font-size: $font-large;
  }
  .bioText{
    width: 330px;
  }
}

.profilePhotoLanding {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 320px) {
  .footer {
    font-size: $font-verysmall;
  }
  .socialNav {
    font-size: $font-smallTwo;
  }
}

@media screen and (max-width: 375px) {
  .footer {
    font-size: $font-verysmall;
  }
  .socialNav {
    font-size: $font-regular;
  }
  .container-header {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .profilePhotoLanding {
    height: 135px;
    width: 128px;
  }
}

@media screen and (max-width: 510px) {
  .social-title {
    font-size: $font-largeTwo;
  }
}