.Profile {
  &-container {
    @extend .row, .between-xs, .middle-xs;
    margin-top: 50px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &-image {
    display: flex;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  }
  &-description {
    @extend .column, .col-xs-7;
    padding-left: 0.8rem;
    min-height: 0;
  }
  &-name {
    font-family: $sf-bold;
    width: 203px;
    line-height: 25px;
    font-size: 1.80rem;
    margin: 0;
    color: $black;
    letter-spacing: -0.86px;
  }
  &-profesion {
    @extend .medium;
    font-weight: 600;
    font-size: 15px;
    margin: 10px 0 6px;
  }
  &-company {
    @extend .medium;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    margin-top: -8px;
  }
  &-address {
    @extend .light;
    color: $light-grey;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    margin-top: 3px;
  }
  &-bio {
    @extend .row, .col-xs-12;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 20px;
    p {
      @extend .light;
      font-family: $sf-regular-ubuntu;
      color: $black-ios;
      font-size: 15px;
      text-align-last: center;
      margin: 0px 14px 4px;      
      text-align: left;
      max-width: 100%;
      -ms-word-break: break-all;
      -ms-word-wrap: break-all;
      -webkit-word-break: break-word;
      -webkit-word-wrap: break-word;
      word-break: normal;
      word-wrap: break-word;
      // -webkit-hyphens: auto;
      // -moz-hyphens: auto;
      // hyphens: auto;
    }
  }
  &-button {
    @extend .row, .col-xs-12;
    border: none;
    outline: none;
    margin: 15px 23px 20px;
    &--component {
      box-sizing: border-box;
      font-family: $sf-regular-ubuntu;
      width: 100%;
      border: none;
      outline: none;
      padding: 16px;
      color: #fff;
      background-color: $black;
      border-radius: 50px;
      cursor: pointer;
      &:active {
        border-color: none;
        outline: none;
      }
      &:hover {
        outline: none;
        border: none;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.31);
      }
    }
    &--featured {
      box-sizing: border-box;
      text-align: center;
      font-family: $sf-bold;
      width: 100%;
      border: none;
      padding: 10px;
      background-color: #fff;
      color: $black;
      border: 3px solid $black;
      border-radius: 50px;
      cursor: pointer;
      text-decoration: none;
      outline: none;
      i {
        padding: 0px 25px;
      }
      &:hover {
        text-decoration: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.31);
        color: $black;
        font-size: 17px;
      }
    }
  }
}

@media only screen and (min-width: 375px) {
  .Profile {
    &-image {
      width: 135px;
      height: 135px;
    }
  }
}

@media only screen and (min-width: 414px) {
  .Profile {
    &-image {
      width: 150px;
      height: 150px;
    }
  }
}

@media only screen and (min-width: 425px) {
  .Profile {
    &-image {
      width: 145px;
      height: 145px;
    }
  }
}

@media only screen and (min-width: 48em) {
  .Profile {
    &-name {
      font-size: 1.4rem;
    }
    &-profesion {
      font-size: 1.2rem;
    }
    &-company {
      font-size: 1.2rem;
    }
    &-address {
      font-size: 1rem;
    }
  }
}

@media only screen and (min-width: 64em) {
  .Profile {
    &-description {
      padding-left: 0;
      min-height: 0;
    }
    &-name {
      font-size: 1.6rem;
      margin: 0px 15px 6.4px 25px;
    }
    &-profesion {
      font-size: 1.2rem;
      margin: 13.4px 15px 0px 25px;
      font-size: 17px;
      line-height: normal;
    }
    &-company {
      margin: 3.4px 0px 0px 25px;
      font-size: 17px;
      line-height: normal;
    }
    &-address {
      font-size: 1rem;
      margin: 7.4px 15px 0px 25px;
    }
  }
}
