.NotFound {
  max-width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  box-sizing: border-box;
  overflow: hidden;
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  &-title {
    text-align: center;
    font-size: 1.5rem;
    color: $color-primary;
  }
}
