//framework
@import "frame/variables";
@import "frame/normalize";
@import "frame/fonts";
@import "frame/icons";
@import "frame/grid";

//components
@import "components/modals";
@import "components/buttons";
@import "components/alerts";
@import "components/header";
@import "components/footer";
@import "components/profile";
@import "components/not-found";
@import "components/social-grid";
@import "../views/Open/Landing/landing";
@import "../views/Open/BuySocialMaster/buySocialMaster";

.red {
    background-color: red;
}

.blue {
    background-color: blue;
}

.green {
    background-color: green;
}

main {
    margin: auto;
    min-height: calc(100vh - 2rem);
}

@media only screen and (min-width: 426px) {
    main {
        max-width: 80%;
    }
}

@media only screen and (min-width: 600px) {
    main {
        max-width: 50%;
    }
}

@media only screen and (min-width: 64em) {
    main {
        max-width: 400px;
    }
}
